.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
}

.board-container,
.speed-slider {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
}

.board-border {
  padding: 10px;
  background-color: #fef17e;
  box-shadow: 0 0 20px black;
  display: flex;
  flex-direction: column;
}

.board {
  position: relative;
  background-color: #fef17e;
  display: grid;
  grid-gap: min(2vh, 2vw);
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(11, 1fr);
  padding: 20px;
  border: 2px solid black;
}
.field.normal {
  background-color: white;
}

.field.hidden {
  background-color: transparent;
  border: none;
}

.field {
  width: min(4.8vh, 4.8vw);
  height: min(4.8vh, 4.5vw);
  border-radius: 50%;
  background-color: lightblue;
  border: 2px solid black;
  box-sizing: border-box;
  position: relative;
}

.field.player::before {
  content: "";
  position: absolute;
  width: 70%;
  height: 70%;
  top: 15%;
  left: 15%;
  border-radius: 50%;
  border: 2px solid grey;
  box-sizing: border-box;
  // background-color: blue;
}

.field.green,
.field.player-green::before {
  background-color: #04ff00;
}

.field.red,
.field.field.player-red::before {
  background-color: #fe0000;
}

.field.blue,
.field.field.player-blue::before {
  background-color: #45caef;
}

.field.yellow,
.field.field.player-yellow::before {
  background-color: #f7f718;
}
.start-field {
  filter: opacity(0.5);
}

.action-button {
  background-color: chocolate;
  cursor: pointer;
  width: 50px;
  height: 28px;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-container {
  display: flex;
  justify-content: space-evenly;
}

.player-display {
  position: absolute;
  max-height: 35vh;
  overflow-y: auto;
}
.winner {
  background-color: #fef17e;
  padding: 5px 20px;
  border-radius: 10px;
}
ul, ol {
  columns: 3;
}
.dice-display {
  ul {
    list-style-type: none;
  }
  .button {
    background-color: chocolate;
    padding: 1px 2px;
    border-radius: 5px;
    margin: 4px 2px;
    cursor: pointer;
  }
  .fa-trash-alt, .fa-plus{
    margin: auto;
    margin-left: 4px;
    padding: 6px 4px;
    margin-bottom: 5px;
  }
  .fa-plus{
    margin-left: 10px;
  }
  input{
    width: 60%;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.start-many-simulations{
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 300px;
  input{
    display: inline-block;
  }
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  background-color: white;
}
.start-many-simulations>div{
  display: flex;
}